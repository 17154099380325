import { GET_USERS_FAILD, GET_USERS_REQUEST, GET_USERS_SUCCESS } from "./constants";

export const getUsersRequest = (payload) => ({
  type: GET_USERS_REQUEST,
  payload
})

export const getUsersSuccess = (payload) => ({
  type: GET_USERS_SUCCESS,
  payload
})

export const getUserFaild = () => ({
  type: GET_USERS_FAILD
})
