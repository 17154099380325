import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
// import { Nav, NavItem, NavLink, UncontrolledTooltip, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from "reactstrap";
import { Nav,Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from "reactstrap";
// import classnames from "classnames";
import { connect } from "react-redux";

import { setActiveTab } from "../../redux/actions";

//Import Images
import logo from "../../assets/images/logo.png"
import avatarEmpty from "../../assets/images/users/avatar-empty.png";

//i18n

function LeftSidebarMenu(props) {

    const history = useHistory();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpenMobile, setDropdownOpenMobile] = useState(false);


    const toggle = () => setDropdownOpen(!dropdownOpen);
    const toggleMobile = () => setDropdownOpenMobile(!dropdownOpenMobile);

    const toggleTab = tab => {
        props.setActiveTab(tab)
    }

    const activeTab = history.location.pathname || props.activeTab;

    const handleClick = (page) => {
        toggleTab(page)
        history.push(page)
    }
    
    const getUserAvatar = () => {
        if (props.user && props.user.user && props.user.user.thumbnail) {
            return props.user.user.thumbnail.url;
        }
        return avatarEmpty
    }

    return (
        <React.Fragment>
            <div className="side-menu flex-lg-column mr-lg-1">
                {/* LOGO */}
                <div className="navbar-brand-box">
                    <Link to="/" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src={logo} alt="logo" height="50" />
                        </span>
                    </Link>

                    <Link to="/" className="logo logo-light">
                        <span className="logo-sm">
                            <img src={logo} alt="logo" height="50" />
                        </span>
                    </Link>
                </div>
                {/* end navbar-brand-box  */}

                {/* Start side-menu nav */}
                <div className="flex-lg-column my-auto">
                    <Nav pills className="side-menu-nav justify-content-center" role="tablist">
                        {/* <NavItem id="Users">
                            <NavLink id="pills-chat-tab" className={classnames({ active: activeTab === '/users' })} onClick={() => handleClick('/users')}>
                                <i className="ri-folder-user-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Users" placement="top">
                            Users
                        </UncontrolledTooltip> */}
                        
                        {/* <NavItem id="Chats">
                            <NavLink id="pills-chat-tab" className={classnames({ active: activeTab === '/dashboard' })} onClick={() => { handleClick('/dashboard'); }}>
                                <i className="ri-message-3-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Chats" placement="top">
                            Chats
                        </UncontrolledTooltip> */}
                        {/* <NavItem id="Settings">
                            <NavLink id="pills-setting-tab" className={classnames({ active: activeTab === '/settings' })} onClick={() => { handleClick('settings'); }}>
                                <i className="ri-settings-2-line"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Settings" placement="top">
                            Settings
                        </UncontrolledTooltip> */}
                        <Dropdown nav isOpen={dropdownOpenMobile} toggle={toggleMobile} className="profile-user-dropdown d-inline-block d-lg-none">
                            <DropdownToggle nav>
                                {props.user !== null && props.user.user !== null && 
                                <img src={props.user.user.avatar} alt="pombeebee" className="profile-user rounded-circle" />
                                }
                            </DropdownToggle>
                            <DropdownMenu>
                                {/* <DropdownItem onClick={() => { toggleTab('profile'); }}>Profile <i className="ri-profile-line float-right text-muted"></i></DropdownItem>
                                <DropdownItem onClick={() => { toggleTab('settings'); }}>Setting <i className="ri-settings-3-line float-right text-muted"></i></DropdownItem>
                                <DropdownItem divider /> */}
                                <DropdownItem href="/logout">Log out <i className="ri-logout-circle-r-line float-right text-muted"></i></DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                </div>
                {/* end side-menu nav */}

                <div className="flex-lg-column d-none d-lg-block">
                    <Nav className="side-menu-nav justify-content-center">
                        <Dropdown nav isOpen={dropdownOpen} className="btn-group dropup profile-user-dropdown" toggle={toggle}>
                            <DropdownToggle nav>
                            <img src={getUserAvatar()} alt="" className="profile-user rounded-circle" />
                            </DropdownToggle>
                            <DropdownMenu>
                                {/* <DropdownItem onClick={() => { toggleTab('profile'); }}>Profile <i className="ri-profile-line float-right text-muted"></i></DropdownItem>
                                <DropdownItem onClick={() => { toggleTab('settings'); }}>Setting <i className="ri-settings-3-line float-right text-muted"></i></DropdownItem>
                                <DropdownItem divider /> */}
                                <DropdownItem href="/logout">Log out <i className="ri-logout-circle-r-line float-right text-muted"></i></DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                </div>
                {/* Side menu user */}
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { user } = state.Auth
    return {
      ...state.Layout,
      user
    };
};

export default connect(mapStatetoProps, {
    setActiveTab
})(LeftSidebarMenu);