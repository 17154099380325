import {
    CHAT_USER, ACTIVE_USER,FULL_USER, ADD_LOGGED_USER, CREATE_GROUP,
    FULL_USER_SUCCESS, ADD_MESSAGES, MAKE_AS_READ, GET_NEW_USER, GET_NEW_USER_SUCCESS, SUBSCRIBE_DEVICE_TOKEN_SUCCESS,
    SEARCH_USER, SEARCH_USER_SUCCESS, CREATE_CHAT, CREATE_CHAT_SUCCESS, SEEN_MESSAGE, LOAD_MORE_USER, LOAD_MORE_USER_SUCCESS,
    EDIT_MESSAGE, EDIT_MESSAGE_SUCCESS, ON_EDIT_MESSAGE, ON_STOP_EDIT_MESSAGE, ON_LISTEN_EDIT_MESSAGE, FETCH_MESSAGE_SUCCESS, ADD_MESSAGE
} from './constants';

import {activeUser} from './actions';
import { messageParser } from '../../helpers/chatParser';

const INIT_STATE = {
	active_user : 0,
    new_user: '',
    search_users: [],
    admins: [],
    next_user_page: 1,
    is_load_user: false,
    can_load_user: true,
    users: null,
    groups : [],
    contacts : [],
    selected_edit_msg: {
        id: null,
        content: null
    }
};

const Chat = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHAT_USER:
            return { ...state };

        case ACTIVE_USER:
            return { 
                ...state,
                active_user : action.payload };
                
        case FULL_USER:
            return { 
                ...state,
                users : action.payload };

        case FULL_USER_SUCCESS:
            return { 
                ...state,
                users : action.payload,
                next_user_page: ++state.next_user_page
            };
        case SEARCH_USER:
            return { 
            	...state};

        case SEARCH_USER_SUCCESS:
            return { 
                ...state,
                search_users : action.payload };
        case CREATE_CHAT:
            return { 
                ...state};

        case CREATE_CHAT_SUCCESS:
            return { 
                ...state, users: [
                    action.payload, ...state.users,
                ]};
        case GET_NEW_USER:
            return { 
                ...state,
                users : [
                    ...state.users,
                ],
                new_user: ''
            };
        case GET_NEW_USER_SUCCESS:
            let active_user = state.active_user
            return {
                ...state, users: [
                    action.payload, ...state.users,
                ],
                active_user: ++active_user
            }

        case ADD_LOGGED_USER:
            const newUser =  action.payload
            return{
                ...state, users : [
                    ...state.users, newUser
                ]
            };

        case CREATE_GROUP :
            const newGroup =  action.payload
            return {
                ...state, groups : [
                    ...state.groups, newGroup
                ]
            }
        case ADD_MESSAGES:
            const messages = action.payload;
            let userState = state.users;
            let activeConversation = state.active_user;
            let newconversation = false;
            let conversationIndex;
            let userType;
            messages.forEach(m => {
                userType = m.userType;
                for (let index = 0; index < state.users.length; index++) {
                    const conversation = state.users[index];
                    if (conversation.id === m.conversation_id) {
                        state.users[index].lastMessage = messageParser(m)
                        conversationIndex = index;
                        let isExist = false;
                        if (!state.users[index].messages) {
                            state.users[index].messages = [];
                        }
                        (state.users[index].messages).forEach(currentMessage => {
                            if (currentMessage.id === m.id) {
                                isExist = true;
                            }
                        });
                        if (!isExist) {
                            state.users[index].messages.push(m)
                        }
                        if(userType === 'receiver') {
                            state.users[index].unRead = state.users[index].unRead+1;
                        }
                        newconversation = state.users[index];
                        state.users.splice(index, 1);
                    }
                }
            });
            if(newconversation === false && userType === 'receiver') {
                return { 
                    ...state, users: [
                        ...userState
                    ],
                    new_user: messages[0].conversation_id
                }
            }
            if(userType === 'sender') {
                return { 
                    ...state, users: [
                        newconversation, ...state.users,
                    ],
                    active_user: 0
                }
            }else {
                if(state.active_user === 0) {
                    if(conversationIndex === 0) {
                        activeConversation = 0;
                    }else {
                        activeConversation = 1;
                    }
                    return {
                        ...state, users: [
                            newconversation, ...state.users,
                        ],
                        active_user: activeConversation
                    }
                }
                activeUser(++activeConversation)
                return { 
                    ...state, users: [
                        newconversation, ...state.users,
                    ]
                }
            }
        case MAKE_AS_READ:
            const conversationId = action.payload
            for (let index = 0; index < state.users.length; index++) {
                if (state.users[index].id === conversationId) {
                    state.users[index].unRead = 0
                }
            }
            return {
                ...state, users: [
                    ...state.users
                ]
            }
        case SEEN_MESSAGE:
            for (let index = 0; index < state.users.length; index++) {
                if(state.users[index].id === action.payload) {
                    for (let index2 = 0; index2 < state.users[index].messages.length; index2++) {
                        state.users[index].messages[index2].isRead = true;
                    }
                }
            }
            return { 
                ...state, users: [
                    ...state.users
                ]
            }
        case LOAD_MORE_USER:
            return { 
                ...state,
                next_user_page: ++state.next_user_page,
                is_load_user: true
            }
        case LOAD_MORE_USER_SUCCESS:
            if(action.payload.length === 0) {
                return {
                    ...state,
                    can_load_user: false,
                    is_load_user: false
                }
            }
            return { 
                ...state,
                users: [
                    ...state.users.concat(action.payload),
                ],
                is_load_user: false
            }
        case SUBSCRIBE_DEVICE_TOKEN_SUCCESS:
            return { 
                ...state
            }
        case ON_EDIT_MESSAGE:
            return { 
                ...state,
                selected_edit_msg: action.payload
            }
        case ON_STOP_EDIT_MESSAGE:
            return { 
                ...state,
                selected_edit_msg: {
                    id: null,
                    content: null
                }
            }
        case EDIT_MESSAGE:
            return { 
                ...state,
                selected_edit_msg: {
                    id: null,
                    content: null
                },
            }
        case ON_LISTEN_EDIT_MESSAGE:
        case EDIT_MESSAGE_SUCCESS:
            let editedMsg = action.payload;
            let listUsers = state.users;
            for (let i = 0; i < listUsers.length; i++) {
                if(listUsers[i].id === editedMsg.conversation_id) {
                    for (let j = 0; j < listUsers[i].messages.length; j++) {
                        if(listUsers[i].messages[j].id === editedMsg.id) {
                            listUsers[i].messages[j].message = editedMsg.message;
                            break
                        }
                    }
                    break;
                }
            }
            if(action.type === EDIT_MESSAGE_SUCCESS) {
                return { 
                    ...state,
                    selected_edit_msg: {
                        id: null,
                        content: null,
                    },
                    users: listUsers
                }
            }else {
                return { 
                    ...state,
                    selected_edit_msg: {
                        id: state.selected_edit_msg.id,
                        content: state.selected_edit_msg.content
                    },
                    users: listUsers
                }
            }
        case FETCH_MESSAGE_SUCCESS:
            if(action.payload.messages.length !== 0) {
                let foundIndex2;
                let listUsers2 = state.users;
                for (const key in listUsers2) {
                    if (listUsers2[key].id === action.payload.conversationId) {
                        foundIndex2 = key;
                    }
                }
                if (foundIndex2 !== undefined) {
                    listUsers2[foundIndex2].messages = action.payload.messages;
                }
                return { 
                    ...state,
                    users: [...listUsers2]
                }
            }
            break;
        case ADD_MESSAGE:
            let foundIndex3;
            let listUsers3 = state.users;
            for (const key in listUsers3) {
                if (listUsers3[key].id === action.payload.conversationId) {
                    foundIndex3 = key;
                }
            }
            if (foundIndex3 !== undefined) {
                if (listUsers3[foundIndex3].messages === null || listUsers3[foundIndex3].messages === undefined) {
                    listUsers3[foundIndex3].messages = [];
                }
                listUsers3[foundIndex3].messages.push(action.payload.newMessage);
            }
            return { 
                ...state,
                users: [...listUsers3]
            }
        default: return { ...state };
    }
}

export default Chat;