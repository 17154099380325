export const CHAT_USER = "CHAT_USER";
export const ACTIVE_USER = "ACTIVE_USER";
export const FULL_USER = "FULL_USER";
export const FULL_USER_SUCCESS = "FULL_USER_SUCCESS";
export const ADD_LOGGED_USER = "ADD_LOGGED_USER";
export const CREATE_GROUP = "CREATE_GROUP";
export const ADD_MESSAGES = "ADD_MESSAGES";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const READ_MESSAGE = "READ_MESSAGE";
export const MAKE_AS_READ = "MAKE_AS_READ";
export const GET_NEW_USER = "GET_NEW_USER";
export const GET_NEW_USER_SUCCESS = "GET_NEW_USER_SUCCESS";
export const SEARCH_USER = "SEARCH_USER";
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";
export const CREATE_CHAT = "CREATE_CHAT";
export const CREATE_CHAT_SUCCESS = "CREATE_CHAT_SUCCESS";
export const SEEN_MESSAGE = "SEEN_MESSAGE";
export const LOAD_MORE_USER = "LOAD_MORE_USER";
export const LOAD_MORE_USER_SUCCESS = "LOAD_MORE_USER_SUCCESS";
export const SUBSCRIBE_DEVICE_TOKEN = "SUBSCRIBE_DEVICE_TOKEN";
export const SUBSCRIBE_DEVICE_TOKEN_SUCCESS = "SUBSCRIBE_DEVICE_TOKEN_SUCCESS";
export const ON_EDIT_MESSAGE = "ON_EDIT_MESSAGE";
export const ON_STOP_EDIT_MESSAGE = "ON_STOP_EDIT_MESSAGE";
export const ON_LISTEN_EDIT_MESSAGE = "ON_LISTEN_EDIT_MESSAGE";
export const EDIT_MESSAGE = "EDIT_MESSAGE";
export const EDIT_MESSAGE_SUCCESS = "EDIT_MESSAGE_SUCCESS";
export const FETCH_MESSAGE = "FETCH_MESSGAE";
export const FETCH_MESSAGE_SUCCESS = "FETCH_MESSAGE_SUCCESS";
export const ADD_MESSAGE = "ADD_MESSAGE";