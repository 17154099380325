import { all, call, put, takeEvery } from "redux-saga/effects";
import { APIClient, setAuthorization } from "../../helpers/apiClient";
import { getAccessToken } from "../../helpers/user";
import { GET_USERS_REQUEST } from "./constants";

import * as actions from './actions'

const get = new APIClient().get;

function* getUserRequest({payload}) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(get, 'users');
    yield put(actions.getUsersSuccess(response.data));
  } catch (error) {
  }
}

export default function* usersSaga() {
  yield all([takeEvery(GET_USERS_REQUEST, getUserRequest)])
}
