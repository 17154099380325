import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { APIClient, setAuthorization } from '../../helpers/apiClient';
import {chatParser} from '../../helpers/chatParser';
import {
    setFullUserSuccess, addMessages, markAsRead, getNewUserSuccess,
    searchUserSuccess, creatChatSuccess, loadMoreUserSuccess, subscribeDeviceTokenSuccess,
    editMessageSuccess,
    fetchMessagesSuccess
} from './actions';
import {messageParser} from '../../helpers/chatParser'
import {getAccessToken} from '../../helpers/user'
import { 
    GET_NEW_USER, SEARCH_USER, FULL_USER, SEND_MESSAGE, READ_MESSAGE, CREATE_CHAT, LOAD_MORE_USER,
    SUBSCRIBE_DEVICE_TOKEN, EDIT_MESSAGE, FETCH_MESSAGE
} from './constants';

const get = new APIClient().get;
const create = new APIClient().create;
const update = new APIClient().update;


function* getNewUserConversation({payload}) {
    try {
        setAuthorization(getAccessToken());
        const response = yield call(get, '/conversations?conversation_id='+payload);
        let listChat = [];
        (response.data.data).forEach(conversation => {
            (listChat).push(chatParser(conversation))
        });
        if(listChat.length > 0) {
            yield put(getNewUserSuccess(listChat[0]));
        }

    } catch (error) {
    }
}

function* searchUser({payload}) {
    try {
        if(payload === '') {return}
        setAuthorization(getAccessToken());
        const response = yield call(get, '/admin/conversations?keyword='+payload+'&limit=-1');
        let listChat = [];
        (response.data.data).forEach(conversation => {
            (listChat).push(chatParser(conversation))
        });
        yield put(searchUserSuccess(listChat));

    } catch (error) {
    }
}

function* setFullUser() {
    try {
        setAuthorization(getAccessToken());
        const response = yield call(get, '/admin/conversations');
        let listChat = [];
        (response.data.data).forEach(conversation => {
            (listChat).push(chatParser(conversation))
        });
        yield put(setFullUserSuccess(listChat));
    } catch (error) {
        console.log(error)
    }
}

function* send({payload}) {
    var formData = new FormData();
    let type;
    if (payload.isFileMessage || payload.isImageMessage) {
        type = "ATTACHMENT"
        formData.append("attachment", payload.fileData)
    } else {
        type = "TEXT"
        formData.append("message", payload.message)
    }
    formData.append("conversation_id", payload.conversation_id);
    formData.append("type", type);
    setAuthorization(getAccessToken());
    try {
        let response;
        if (type === "TEXT") {
            response = yield call(create, '/api/messages', formData);
        } else {
            response = yield call(create, '/api/messages', formData,
            {
                headers: {
                    'Authorization': 'Bearer '+getAccessToken(),
                    'Content-Type': 'multipart/form-data'
                }
            });
        }
        let newMessage = messageParser(response.data)
        newMessage.userType = payload.userType;
        yield put(addMessages([newMessage]));
    } catch (error) {
    }
}

function* edit({payload}) {
    try {
        setAuthorization(getAccessToken());
        const response = yield call(update, '/chats/messages/'+payload.id+'/actions/update', {message : payload.message });
        yield put(editMessageSuccess(response.data));
    } catch (error) {
    }
}

function* read({payload}) {
    try {
        setAuthorization(getAccessToken());
        yield call(update, '/api/conversations/'+payload+'/mark-as-read');
        yield put(markAsRead(payload));
    } catch (error) {
    }
}

function* createChat({payload}) {
    var formData = new FormData();
    formData.append("object_id", payload.object_id);
    formData.append("object_type", payload.object_type);
    setAuthorization(getAccessToken());
    try {
        let response = yield call(create, '/admin/actions/create-chat', formData);
        let listChat = [];
        (response.data.data).forEach(conversation => {
            (listChat).push(chatParser(conversation))
        });
        if(listChat.length > 0) {
            yield put(creatChatSuccess(listChat[0]));
        }
    } catch (error) {
    }
}

function* loadMoreUser(action) {
    try {
        setAuthorization(getAccessToken());
        const response = yield call(get, '/admin/conversations?page='+action.payload);
        let listChat = [];
        (response.data.data).forEach(conversation => {
            (listChat).push(chatParser(conversation))
        });
        yield put(loadMoreUserSuccess(listChat));
    } catch (error) {
    }
}


function* fetchMessages({payload}) {
    setAuthorization(getAccessToken());
    try {
        let response = yield call(get, '/admin/messages?conversation_id='+payload.conversation_id+"&limit=-1");
        let listItems = [];
        (response.data).forEach(item => {
            (listItems).push(messageParser(item))
        });
        yield put(fetchMessagesSuccess(payload.conversation_id, listItems.reverse()));
    } catch (error) {
    }
}

function* subscribeDevice({payload}) {
    var formData = new FormData();
    formData.append("token", payload.token);
    formData.append("topic", 'ADMIN');
    setAuthorization(getAccessToken());
    try {
        yield call(create, '/cloudmessages/actions/subscribe', formData);
        yield put(subscribeDeviceTokenSuccess({}));
    } catch (error) {
    }
}

export function* watchSetFull() {
    yield takeEvery(FULL_USER, setFullUser);
}

export function* watchSend() {
    yield takeEvery(SEND_MESSAGE, send);
}

export function* watchEdit() {
    yield takeEvery(EDIT_MESSAGE, edit);
}

export function* watchRead() {
    yield takeEvery(READ_MESSAGE, read);
}

export function* watchGetNewUserConversation() {
    yield takeEvery(GET_NEW_USER, getNewUserConversation);
}

export function* watchSearchUser() {
    yield takeEvery(SEARCH_USER, searchUser);
}

export function* watchCreateChat() {
    yield takeEvery(CREATE_CHAT, createChat);
}

export function* watchLoadMoreUser() {
    yield takeEvery(LOAD_MORE_USER, loadMoreUser);
}

export function* watchSubscribeDevice() {
    yield takeEvery(SUBSCRIBE_DEVICE_TOKEN, subscribeDevice);
}

export function* watchFetchMessages() {
    yield takeEvery(FETCH_MESSAGE, fetchMessages);
}

function* chatSaga() {
    yield all([
        fork(watchSetFull), 
        fork(watchSend),
        fork(watchRead),
        fork(watchGetNewUserConversation),
        fork(watchSearchUser),
        fork(watchCreateChat),
        fork(watchLoadMoreUser),
        fork(watchSubscribeDevice),
        fork(watchEdit),
        fork(watchFetchMessages)
    ]);
}

export default chatSaga;