import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { APIClient, setAuthorization } from '../../helpers/apiClient';
import { getFirebaseBackend } from "../../helpers/firebase";


import {
    LOGIN_USER,
    LOGOUT_USER,
    REGISTER_USER,
    FORGET_PASSWORD,
    UPDATE_USER
} from './constants';


import {
    loginUserSuccess,
    logoutUser,
    registerUserSuccess,
    forgetPasswordSuccess,
    apiError,
    updateUserSuccess
} from './actions';
import { getAccessToken } from '../../helpers/user';


//Initilize firebase
const fireBaseBackend = getFirebaseBackend();


/**
 * Sets the session
 * @param {*} user 
 */

const create = new APIClient().create;
const update = new APIClient().update;

/**
 * Login the user
 * @param {*} payload - username and password 
 */
function* login({ payload: { username, password, history } }) {
    try {
        if(process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            const response = yield call(fireBaseBackend.loginUser, username, password);
            yield put(loginUserSuccess(response));
        } else {
            const response = yield call(create, '/api/auth/login', {
                email: username,
                password: password
            });
            localStorage.setItem("authUser", JSON.stringify(response.data));
            yield put(loginUserSuccess(response.data));
        }
        history.push('/dashboard');
    } catch (error) {
        yield put(apiError(error));
    }
}


/**
 * Logout the user
 * @param {*} param
 */
function* logout({ payload: { history } }) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
            yield call(fireBaseBackend.logout);
        }else{
            let token = JSON.parse(localStorage.getItem('authUser')).token;
            setAuthorization(token); // add token in header
            const response = yield call(create, '/api/auth/logout', null);
            yield put(logoutUser(response));
            localStorage.removeItem("authUser"); // remove token in localStorage in browser
        }
        yield call(() => {
            history.push("/login");
        });
    } catch (error) {
        localStorage.removeItem("authUser");
    }
}

/**
 * Register the user
 */
function* register({ payload: { user } }) {
    try {
        const email = user.email;
        const password = user.password;
        if(process.env.REACT_APP_DEFAULTAUTH === "firebase"){
            const response = yield call(fireBaseBackend.registerUser, email, password);
            yield put(registerUserSuccess(response));
        } else {
            const response = yield call(create, '/register', user);
            yield put(registerUserSuccess(response));
        }
        
    } catch (error) {
        yield put(apiError(error));
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { email } }) {
    try {
        if(process.env.REACT_APP_DEFAULTAUTH === "firebase"){
            const response = yield call(fireBaseBackend.forgetPassword, email);
            if (response) {
              yield put(
                forgetPasswordSuccess(
                  "Reset link are sended to your mailbox, check there first"
                )
              );
            }
        } else {
            const response = yield call(create, '/forget-pwd', { email });
            yield put(forgetPasswordSuccess(response));
        }
    } catch (error) {
        yield put(apiError(error));
    }
}

/**
 * Update the user
 */
function* updateUser({ payload }) {
    try {
        setAuthorization(getAccessToken());
        yield call(update, '/users/me', payload);
        let currentUser = JSON.parse(localStorage.getItem('authUser'))
        localStorage.setItem("authUser", JSON.stringify({...currentUser, user: { ...currentUser.user, ...payload }}))
        yield put(updateUserSuccess([payload]));
    } catch (error) {
        console.log(error)
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchUpdateUser() {
    yield takeEvery(UPDATE_USER, updateUser);
}

function* authSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgetPassword),
        fork(watchUpdateUser),
    ]);
}

export default authSaga;