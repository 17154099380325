import {
  GET_USERS_SUCCESS
} from './constants';

const INIT_STATE = [];

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload.data
      }
    }
    default: return INIT_STATE;
  }
}

export default Users;