
import emptyAvatar from "../assets/images/users/avatar-empty.png";
// import config from "../config";
// import TimeAgo from 'javascript-time-ago'
// import en from 'javascript-time-ago/locale/en'

// TimeAgo.addDefaultLocale(en)
// const timeAgo = new TimeAgo('en-US')

const datetimeConverter = (time) => {
    if (time === undefined) {
        return "Just now";
    }
    let date = new Date(time)
    let hour = String(date.getHours()).padStart(2, "0");
    let minute = String(date.getMinutes()).padStart(2, "0");
    let selectedYear = date.getFullYear();
    let selectedMonth = String(date.getMonth() + 1).padStart(2, "0");
    let selectedDay = String(date.getDate()).padStart(2, "0");

    return `${selectedDay}-${selectedMonth}-${selectedYear} ${hour}:${minute}`
}

export function chatParser(conversation) {
    var record = { 
        id : conversation.id, 
        type: conversation.type,
        profilePicture : null, 
        status : "away",
        createdBy: conversation.created_by,
        lastMessage: messageParser(conversation.last_message),
        email: null,
        unRead: 0
    }
    if (record.profilePicture === "" || record.profilePicture === null) {
        record.profilePicture = emptyAvatar
    }
    record.messages = [];
    if (conversation.members) {
        conversation.members.forEach((member) => {
            if (member.user_id != "admin" && member.user) {
                record.name = member.user.name;
                record.email = member.user.email;
                if (member.user.thumbnail) {
                    record.profilePicture = member.user.thumbnail.url;
                }
            }
            if (member.user_id === "admin") {
                record.unRead = member.number_unread
            }
        })
        
    }
    return record
}

export function convertPostMessage(newMessage) {
    return {
        conversation_id: newMessage.conversation_id,
        message: newMessage.message,
        type: "TEXT"
    }
}

export function messageParser(m) {
    if (m == null) {
        return null;
    }
    let item = { 
        id: m.id,
        conversation_id: m.conversation_id, 
        message: m.message, 
        time: datetimeConverter(m.created_at),
        isImageMessage : m.type === "ATTACHMENT" && m.attachment_type === "IMAGE", 
        isFileMessage : m.type === "ATTACHMENT" && m.attachment_type !== "IMAGE",
        sender: m.admin_name,
        adminAvatar: m.admin_avatar,
        adminId: m.admin_id,
        userId: m.user_id,
        isRead: m.status === "READ" ? true : false
    }
    if (item.isImageMessage) {
        item.imageMessage = [{image: m.attachment_url}]
    }
    if (item.isFileMessage) {
        var res = item.message.split("/")
        item.message = res[res.length - 1]
        item.fileMessage = m.attachment_url
    }
    return item
}