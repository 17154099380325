import {
    CHAT_USER,ACTIVE_USER,FULL_USER, ADD_LOGGED_USER, CREATE_GROUP,
    FULL_USER_SUCCESS, SEND_MESSAGE, ADD_MESSAGES, MAKE_AS_READ, READ_MESSAGE, GET_NEW_USER, GET_NEW_USER_SUCCESS,
    SEARCH_USER, SEARCH_USER_SUCCESS, CREATE_CHAT, CREATE_CHAT_SUCCESS, SEEN_MESSAGE, LOAD_MORE_USER, LOAD_MORE_USER_SUCCESS,
    SUBSCRIBE_DEVICE_TOKEN, SUBSCRIBE_DEVICE_TOKEN_SUCCESS, EDIT_MESSAGE, EDIT_MESSAGE_SUCCESS, ON_EDIT_MESSAGE, ON_STOP_EDIT_MESSAGE,
    ON_LISTEN_EDIT_MESSAGE, FETCH_MESSAGE, FETCH_MESSAGE_SUCCESS, ADD_MESSAGE
} from './constants';

export const chatUser = () => ({
    type: CHAT_USER
});

export const activeUser = (userId) => ({
    type: ACTIVE_USER,
    payload : userId
});

export const setFullUser = () => ({
    type: FULL_USER,
});

export const setFullUserSuccess = (fullUser) => ({
    type: FULL_USER_SUCCESS,
    payload : fullUser
});

export const sendMessage = (message) => ({
    type: SEND_MESSAGE,
    payload: message
})

export const addMessages = (messages) => ({
    type: ADD_MESSAGES,
    payload: messages
})

export const readMessage = (conversationId) => ({
    type: READ_MESSAGE,
    payload: conversationId
})

export const seenMessage = (conversationId) => ({
    type: SEEN_MESSAGE,
    payload: conversationId
})

export const markAsRead = (conversationId) => ({
    type: MAKE_AS_READ,
    payload: conversationId
})

export const addLoggedinUser = (userData) => ({
    type: ADD_LOGGED_USER,
    payload : userData
});

export const createGroup = (groupData) => ({
    type : CREATE_GROUP,
    payload : groupData
})

export const getNewUser = (conversationId) => ({
    type: GET_NEW_USER,
    payload: conversationId
});

export const getNewUserSuccess = (userData) => ({
    type: GET_NEW_USER_SUCCESS,
    payload : userData
});

export const searchUser = (keyword) => ({
    type: SEARCH_USER,
    payload: keyword
});

export const searchUserSuccess = (users) => ({
    type: SEARCH_USER_SUCCESS,
    payload : users
});

export const creatChat = (object) => ({
    type: CREATE_CHAT,
    payload: object
});

export const creatChatSuccess = (conversation) => ({
    type: CREATE_CHAT_SUCCESS,
    payload : conversation
});

export const loadMoreUser = (page) => ({
    type: LOAD_MORE_USER,
    payload : page
});

export const loadMoreUserSuccess = (conversation) => ({
    type: LOAD_MORE_USER_SUCCESS,
    payload : conversation
});

export const subscribeDeviceToken = (data) => ({
    type: SUBSCRIBE_DEVICE_TOKEN,
    payload : data
});

export const subscribeDeviceTokenSuccess = () => ({
    type: SUBSCRIBE_DEVICE_TOKEN_SUCCESS,
    payload : {}
});

export const onEditMessage = (message) => ({
    type: ON_EDIT_MESSAGE,
    payload : message
});

export const onStopEditMessage = () => ({
    type: ON_STOP_EDIT_MESSAGE,
    payload : {}
});

export const editMessage = (message) => ({
    type: EDIT_MESSAGE,
    payload : message
});

export const editMessageSuccess = (message) => ({
    type: EDIT_MESSAGE_SUCCESS,
    payload : message
});

export const onListenEditMessage = (message) => ({
    type: ON_LISTEN_EDIT_MESSAGE,
    payload : message
});

export const fetchMessages = (params) => ({
    type: FETCH_MESSAGE,
    payload : params
});

export const fetchMessagesSuccess = (conversationId, messages) => ({
    type: FETCH_MESSAGE_SUCCESS,
    payload : { conversationId, messages }
});

export const addMessage = (conversationId, newMessage) => ({
    type: ADD_MESSAGE,
    payload : {conversationId, newMessage }
});