import React from 'react';
import Routes from './routes/';

//Import Scss
import "./assets/scss/themes.scss";
import 'firebase/messaging';


// //Firebase helper
// import { getToken, onMessageListener } from "./helpers/firebase_message";
// import { registerServiceWorker } from './serviceWorker'

// TODO
// fakeBackend();


function App() {
  // getToken();
  // onMessageListener().then(payload => {
  //   console.log(payload);
  // }).catch(err => console.log('failed: ', err));

  return (
    <Routes />
  );
}
// registerServiceWorker()
export default App;
